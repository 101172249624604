import * as React from "react"
import "./people_grid.css"

export type Person = {
    name: string;
    organization: string;
    location?: string;
    expertise?: string;
    imageName?: string;
}

export const PeopleGrid = (people: Person[], small: boolean = false) => (
    <div className={"people " + (small ? "people-small" : "")}>
        {people.map(person => {
            const image = person.imageName ? `/images/${person.imageName}.jpg` : "/images/placeholder_avatar.png";
            return (<div className="person">
                <img src={image}
                    width={256}
                    alt={person.name} />
                <div className="person__info">
                    <span className="person__name">{person.name}</span>
                    <span className="person__organization">{person.organization}</span>
                    <div className="person__extra_info">
                        {person.location && <span className="person__location">
                            <span className="material-symbols-outlined">pin_drop</span> {person.location}
                        </span>}
                        {person.expertise && <span className="person__expertise">
                            <span className="material-symbols-outlined">work</span> {person.expertise}</span>}
                    </div>
                </div>
            </div>)
        })}
    </div>
)
