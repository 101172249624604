import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Person, PeopleGrid } from "../components/people_grid"

const ADVISORS_IN_RESIDENCE: Person[] = [
    { name: 'Alice Conques', organization: 'Zoi', location: 'Paris', expertise: 'Strategy & Ops', imageName: 'alice_conques' },
    { name: 'Sarah Tadlaoui', organization: 'Google', location: 'Paris', expertise: 'Product', imageName: 'sarah_tadlaoui' },
    { name: 'Ismail Najim', organization: 'Google', location: 'London', expertise: 'Product', imageName: 'ismail_najim' },
    { name: 'Thomas Scialom', organization: 'Meta AI', location: 'Paris', expertise: 'Research', imageName: 'thomas_scialom' },
    { name: 'David Moufarege', organization: 'Fieldwire', location: 'Paris', expertise: 'GTM', imageName: 'david_moufarege' },
    { name: 'Armand Joulin', organization: 'DeepMind', location: 'Paris', expertise: 'Research', imageName: 'armand_joulin' },
    { name: 'Dan Mankowicz', organization: 'DeepMind', location: 'London', expertise: 'Research & Applied ML', imageName: 'dan_mankowicz' },
    { name: 'Stef Van Grieken', organization: 'Cradle', location: 'Zurich', expertise: 'Product', imageName: 'stef_van_grieken' },
    { name: 'Dali Kilani', organization: 'FlexAI', location: 'Paris/Germany', expertise: 'Engineering', imageName: 'dali_kilani' },
    { name: 'Inès Chami', organization: 'Numbers Station AI', location: 'New York', expertise: 'Research', imageName: 'ines_chami' },
    { name: 'Zach Gleicher', organization: 'DeepMind', location: 'London', expertise: 'Product', imageName: 'zach_gleicher' },
    { name: 'Sophia Alj', organization: 'DeepMind', location: 'London', expertise: 'Product', imageName: 'sophia_alj' },
    { name: 'Sofia Dahoune', organization: 'No Label Ventures', location: 'Paris', expertise: 'Investor', imageName: 'sofia_dahoune' },
    { name: 'Ahmed Kachkach', organization: 'Google', location: 'Zurich', expertise: 'Applied ML & Engineering', imageName: 'ahmed_kachkach' },
    { name: 'Jon Steinback', organization: 'DeepMind', location: 'London', expertise: 'Product Marketing' },
    { name: 'Margaret Jennings', organization: 'Mistral, OpenAI', location: 'Paris', expertise: 'Product', imageName: 'margaret_jennings' },
    { name: 'Ahmed Dahbi', organization: 'Photoroom', location: 'Paris', expertise: 'Product', imageName: 'ahmed_dahbi' },
    { name: 'Thibault Vrevin', organization: 'X, the Moonshot Factory', location: 'San Francisco', expertise: 'Product', imageName: 'thibault_vrevin' },
    { name: 'Thomas Vuillemin', organization: 'YouTube', location: 'Paris', expertise: 'Product', imageName: 'thomas_vuillemin' },
    { name: 'Kaan Aykac', organization: 'ex-Amazon', location: 'San Francisco', expertise: 'Product', imageName: 'kaan_aykac' },
    { name: 'Francois Lagunas', organization: 'Stupeflix, Hugging Face', location: 'Paris', expertise: 'Engineering', imageName: 'francois_lagunas' },
    { name: 'Nicolas Granatino', organization: 'StemAI', location: 'London', expertise: 'Investor', imageName: 'nicolas_granatino' },
    { name: 'Mehdi Ghissassi', organization: 'DeepMind', expertise: 'Product', imageName: 'mehdi_ghissassi' },
];

const AdvisorsInResidence = () => {
    return (
        <Layout>
            <Seo title="Advisors in residence" />
            <h1>Advisors in residence</h1>
            <p>Each startup funded by StemAI will be matched with a dedicated advisors in residence.</p>
            <p>Our advisors cover the whole gamut, from engineering and research to product, strategy and marketing.</p>
            <p>StemAI advisors in residence will work closely with you to level up your engineering stack, build a product strategy, and leverage state-of-the-art LLM technologies.</p>
            {PeopleGrid(ADVISORS_IN_RESIDENCE, /*small=*/true)}
        </Layout>
    )
}

export default AdvisorsInResidence
